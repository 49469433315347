* {
  box-sizing: border-box;
}

html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: auto;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.image {
  min-width: 100%;
  height: 100%;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-user-drag: none;
}

iframe {
  min-width: 100%
}

.row > div {
  width: 25%;
  height: 18vw;
  min-width: 327px;
  min-height: 235.719px;
  position: relative;
  overflow: hidden;
}

.logo {
  position: fixed;
  width: 140px;
  bottom: 10px;
  left: 0px;
  -webkit-user-drag: none;
  /* opacity: 0.8; */
}
